import React from 'react';
import { MenuItem } from '@material-ui/core';
import clsx from 'clsx';

import Select, { SelectProps } from '../Select';
import { useStyles } from './styles';

type DataSourceTypeSelectProps = Omit<SelectProps, 'children'>;

export default function DataSourceTypeSelect({ className, ...props }: DataSourceTypeSelectProps): JSX.Element {
  const classes = useStyles();
  return (
    <Select className={clsx(className, classes.formControl)} {...props}>
      <MenuItem value="" disabled />
      <MenuItem value="SQLSERVER">SQL Server</MenuItem>
      <MenuItem value="ATHENA">Athena</MenuItem>
      <MenuItem value="SNOWFLAKE">SnowFlake</MenuItem>
    </Select>
  );
}
